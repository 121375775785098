import { UserOutlined } from '@ant-design/icons'
import { Avatar, Badge, Button, Col, Image, Row, Space } from 'antd'
import Link from 'next/link'
import { FaSearch } from 'react-icons/fa'
import { FiShoppingCart } from 'react-icons/fi'
import { socialMediaLinks } from 'utils/constants'
import ViewContainer from './container'
import {
  StyledAuthAvatar,
  StyledAuthName,
  StyledDrawer,
  StyledEmptyCartDrawer,
  StyledEmptyCartHeader,
  StyledHeaderWrapper,
  StyledMainHeader,
  StyledPopOver,
  StyledPopoverButton,
  StyledSocialMediaLinksWrapper,
  StyledUserNav
} from './styles'
import { hasSuperCard } from 'utils/user'
import { UpdatedLegalTerms } from 'components/Modals/index'

export default function Index () {
  return (
    <ViewContainer>
      {({
        state,
        setDrawer,
        drawer,
        basePath,
        menuItems,
        me,
        handleLogout,
        accountItems,
        cartBadge,
        handleCheckCartItems,
        isCartEmpty,
        setIsCartEmpty,
        scInitialPage,
        showUpdateTcModal,
        setShowUpdateTcModal,
        acceptLegalTerms
      }) => (
        <StyledHeaderWrapper>
          {/* updated legal terms modal */}
          <UpdatedLegalTerms visible={showUpdateTcModal} setVisible={setShowUpdateTcModal} handleAccept={acceptLegalTerms} />
          {/* end of updated legal terms modal */}
          {/* Modal for unverified mobile numbers */}
          {/** DRAWER Mobile Display Only */}
          <Row>
            <Col xs={24} sm={24} md={24} lg={0} xl={0}>
              <StyledDrawer
                title=''
                placement='left'
                onClose={() => setDrawer(false)}
                closable
                visible={drawer}
                getContainer={false}
                style={{ zIndex: 10, position: 'fixed' }}
                width={320}
                stylebodytop={state.me.status === 2 && me ? 104 : 64}
              >
                <div className='drawer-content'>
                  <div className='drawer-brand'>
                    <Link href='/'>
                      <img
                        className='logo'
                        src='/images/logos/peri-brand.png'
                        alt='Peri Peri'
                      />
                    </Link>
                  </div>
                  {state.me.status === 2 && me && (
                    <div className='drawer-user'>
                      <Space size={17}>
                        <Avatar
                          src={localStorage.getItem('img')}
                          size={48}
                          icon={<UserOutlined />}
                        />
                        <div className='user-name'>
                          Hi, {me.first_name}!
                          <Link href='/account'>
                            <a className='helper-text'>View Profile</a>
                          </Link>
                        </div>
                      </Space>
                    </div>
                  )}
                  <div className='drawer-body'>
                    {state.me.status === 2 && !me && (
                      <Link href='/auth/login'>
                        <Button type='primary' size='large' block>
                          Login or Create an Account
                        </Button>
                      </Link>
                    )}
                    <div className='menu-links'>
                      <Link href='/'>Home</Link>
                      <Link href='/order-now/categories/more'>Order now!</Link>
                      <Link href='/catalog/categories/more'>Menu</Link>
                      <Link href='/promos'>Promos</Link>
                      <Link href='/my-orders'>Order Tracking</Link>
                      <Link href='/my-orders'>My Orders</Link>
                      <Link href={hasSuperCard() ? '/supercard/my' : '/supercard/buy'}>Supercard</Link>
                      {state.me.status === 2 && me && (
                        <div>
                          <Button
                            type='primary'
                            onClick={() => handleLogout()}
                            disabled={state.logoutState.status === 1}
                          >
                            Logout
                          </Button>
                        </div>
                      )}
                    </div>
                    <div className='drawer-footer'>
                      <Space size={25}>
                        <Image
                          src='/images/hotline-delivery-horizontal.png'
                          alt=''
                          width='auto'
                          height='30'
                          preview={false}
                        />
                      </Space>
                    </div>
                  </div>
                </div>
              </StyledDrawer>
            </Col>
          </Row>
          {/** DRAWER  Mobile Display Only */}
          <StyledSocialMediaLinksWrapper align='middle' justify='end'>
            <Space direction='horizontal' size={11}>
              <Link href='/about-us'>
                <a>About Us</a>
              </Link>
              <Link href='/contact-us'>
                <a>Contact Us</a>
              </Link>
              {socialMediaLinks.map((i) => (
                <a key={i.code} href={i.link} rel='noreferrer' target='_blank'>
                  <Button shape='circle' icon={i.icon} />
                </a>
              ))}
            </Space>
          </StyledSocialMediaLinksWrapper>
          <StyledMainHeader align='top' justify='space-between'>
            <Col xs={2} sm={2} md={2} lg={0} onClick={() => setDrawer(true)}>
              <img
                className='hamburger'
                src='/images/hamburger-icon.png'
                alt='Peri Peri'
              />
            </Col>
            <Col xs={18} sm={18} md={18} lg={7} xl={7}>
              <Link href='/'>
                <img
                  className='branding'
                  src='/images/logos/peri-brand.png'
                  alt='Peri Peri'
                />
              </Link>
            </Col>
            <Col className='menu-wrapper' xs={0} sm={0} md={0} lg={9}>
              {menuItems.map((menu) => (
                <Link href={menu.link} key={menu.title}>
                  <a className={menu.base === basePath ? 'active' : 'menus'}>
                    {menu.title}
                  </a>
                </Link>
              ))}
            </Col>
            <Col className='actions' xs={4} sm={4} md={4} lg={7}>
              {state.me.status === 2 && me && (
                <StyledUserNav size={11} direction='horizontal'>
                  <StyledAuthName>Hi, {me.first_name}</StyledAuthName>
                  <StyledPopOver
                    className='profile-menu'
                    trigger='click'
                    placement='bottomRight'
                    content={() =>
                      accountItems &&
                      accountItems.map((item) => (
                        <StyledPopoverButton
                          key={item.code}
                          onClick={() => item.action()}
                          type='text'
                          icon={item.icon}
                        >
                          {item.title}
                        </StyledPopoverButton>
                      ))
                    }
                  >
                    <StyledAuthAvatar
                      src={
                        typeof localStorage.getItem('profile_img') !==
                        'undefined'
                          ? localStorage.getItem('profile_img')
                          : ''
                      }
                      icon={<UserOutlined style={{ color: 'var(--cognac)' }} />}
                      style={{ backgroundColor: 'var(--cream' }}
                      size={43}
                    />
                  </StyledPopOver>
                  <Link href='/catalog/search'>
                    <Button
                      className='cream-btn'
                      shape='circle'
                      icon={<FaSearch />}
                    />
                  </Link>
                  <Badge
                    count={cartBadge}
                    overflowCount={99}
                    style={{
                      backgroundColor: 'var(--cognac)',
                      marginRight: '10px',
                      marginTop: '2px'
                    }}
                  >
                    <Button
                      className='cream-btn'
                      shape='circle'
                      icon={<FiShoppingCart />}
                      onClick={() => handleCheckCartItems()}
                    />
                  </Badge>
                </StyledUserNav>
              )}
              {!me && (
                <Space size={11} direction='horizontal'>
                  <Link href='/catalog/search'>
                    <Button
                      className='cream-btn'
                      shape='circle'
                      icon={<FaSearch />}
                    />
                  </Link>
                  <Link href='/auth/login'>
                    <Button
                      className='cream-btn'
                      shape='circle'
                      icon={<FiShoppingCart />}
                    />
                  </Link>
                  <Link href='/auth/login'>
                    <Button className='cognac-btn login' block>
                      Login
                    </Button>
                  </Link>
                </Space>
              )}
            </Col>
          </StyledMainHeader>
          {/* empty carts drawer */}
          <StyledEmptyCartDrawer
            placement='right'
            closable
            onClose={() => setIsCartEmpty(false)}
            visible={isCartEmpty}
            width={366}
            title={
              <StyledEmptyCartHeader justify='center' align='middle'>
                <h2>My Cart</h2>
              </StyledEmptyCartHeader>
            }
          >
            <Row className='empty' justify='center' align='middle'>
              <Col span={24}>
                <Image
                  src='/images/empty-cart.png'
                  alt=''
                  height={195}
                  width={206}
                  draggable='false'
                  preview={false}
                />
                <h2>It’s Empty Here!</h2>
                <p>
                  You haven’t added anything to your cart yet! Go back to the
                  menu to start ordering.
                </p>
                <Link href='/catalog/categories/more'>
                  <Button type='primary' onClick={() => setIsCartEmpty()}>
                    Go Back to Menu
                  </Button>
                </Link>
              </Col>
            </Row>
          </StyledEmptyCartDrawer>
        </StyledHeaderWrapper>
      )}
    </ViewContainer>
  )
}
