import styled from 'styled-components'
import { Row, Avatar, Drawer, Space, Popover, Button } from 'antd'

export const StyledHeaderWrapper = styled(Row)`
  position: relative;
  z-index: 3;
`

export const StyledSocialMediaLinksWrapper = styled(Row)`
  background-color: var(--cognac);
  height: 40px;
  width: 100%;
  padding-right: 68px;
  a {
    font-family: KreonRegular, sans-serif;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    color: var(--cream);
  }
  .ant-btn {
    height: 17px;
    width: 17px;
    min-width: 17px !important;
    background-color: #ffefd1;
    svg {
      font-size: 11.5px !important;
      color: var(--cognac);
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`
export const StyledMainHeader = styled(Row)`
  background-image: url(${(props) =>
    props.footer ? '/images/bg/nav-bg-footer.png' : '/images/bg/nav-bg.png'});
  height: ${(props) => (props.footer ? '110px' : '100px')};
  background-size: 100% 100%;
  width: 100%;
  background-color: inherit !important;
  background-repeat: no-repeat;
  padding: ${(props) => (props.footer ? '30px' : '8px')} 22px 46px 22px;
  align-items: center;

  .branding {
    object-fit: contain;
    cursor: pointer;
  }

  .menu-wrapper {
    text-align: ${(props) => (props.footer ? 'left' : 'center')};
    .menus {
      color: var(--cream);
      font-size: 20px;
      font-weight: bold;
      margin-right: 17px;
      :hover {
        color: var(--cognac);
      }
    }
    .active {
      color: var(--cognac) !important;
      font-size: 22px;
      font-weight: bold;
      margin-right: 22px;
    }
  }

  .hamburger {
    height: 20px;
    width: 20px;
  }
  .actions {
    text-align: right;
    .ant-btn {
      width: 108px;
      height: 39px;
    }
    .ant-btn-circle {
      height: 43px;
      width: 43px;
      svg {
        font-size: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: start;
    padding: ${(props) => (props.footer ? '22px' : ' 0 22px 20px 22px')};
    height: auto;
    .branding {
      width: 280px;
      margin-bottom: ${(props) => (props.footer ? '30px' : '0px')};
    }
    .menu-wrapper {
      text-align: ${(props) => (props.footer ? 'center' : 'right')};
    }
    .cognac-btn {
      display: none;
    }
    .actions .ant-btn-circle {
      height: 33px;
      width: 33px;
    }
  }

  @media (max-width: 992px) {
    .menu-wrapper {
      display: ${(props) => (props.footer ? '' : 'none')};
    }
    .login {
      display: none;
    }
    .actions > .cognac-btn {
      display: none;
    }
  }

  @media (min-width: 768px) and (max-width: 1206px) {
    .menu-wrapper {
      text-align: center;
    }
    .branding {
      width: ${(props) => (props.footer ? '220px' : '300px')};
    }
  }

  @media (min-width: 1207px) and (max-width: 1289px) {
    .branding {
      width: 340px;
    }
  }

  @media (min-width: 1290px) and (max-width: 1353px) {
    .branding {
      width: 370px;
    }
  }

  @media (max-width: 1353px) {
    .menu-wrapper > .menus {
      font-size: 16px;
      margin-right: 12px;
    }
  }
`
export const StyledUserNav = styled(Space)`
  @media (max-width: 768px) {
    margin-left: -32px;
  }
`
export const StyledAuthName = styled.h3`
  font-family: KreonRegular;
  color: var(--cream);

  @media (max-width: 998px) {
    display: none;
  }

  @media (max-width: 1206px) {
    font-size: 24px;
  }

  @media (min-width: 1207px) and (max-width: 1289px) {
    font-size: 28px;
  }

  @media (min-width: 1290px) and (max-width: 1353px) {
    font-size: 32px;
  }
`
export const StyledAuthAvatar = styled(Avatar)`
  background-color: var(--cream);
  cursor: pointer !important;

  @media (max-width: 992px) {
    display: none;
  }
`
export const StyledDrawer = styled(Drawer)`
  overflow: auto;
  .ant-drawer-content-wrapper {
    overflow: none;
    box-shadow: none !important;
  }
  .ant-drawer-content {
    background: transparent;
    .ant-drawer-body {
      position: relative;
      padding: 0 50px 0 0;
      .ant-btn-primary {
        font-size: 12px;
      }
    }
    .ant-drawer-close {
      color: #fff !important;
    }
  }
  .drawer-content {
    width: 270px;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: #f8f7f5;
    .drawer-brand {
      text-align: center;
      height: 70px;
      background-image: url('/images/bg/nav-bg.png');
      .logo {
        z-index: 2;
        position: relative;
        width: 95%;
        margin-top: 20px;
      }
    }
    .drawer-user {
      z-index: 1;
      position: relative;
      padding: 30px 26px;
      width: 270px;
      height: 102px;
      background-image: url('/images/bg/nav-bg.png');
      .user-name {
        font-size: 18px;
        color: #f8f7f5;
        a.helper-text {
          font-size: 12px;
          display: block;
          color: #fff;
        }
      }
      .ant-avatar {
        border: 2px solid #fff;
      }
    }
    .drawer-body {
      overflow: auto;
      z-index: 0;
      top: ${(props) => (props.stylebodytop ? props.stylebodytop : 64)}px;
      right: 0;
      left: 0;
      bottom: 0;
      position: absolute;
      padding: 48px 26px;
      background-image: url('/images/pattern8.png');
      background-repeat: no-repeat;
      background-size: contain;

      .menu-links {
        margin-top: 40px;
        a {
          margin-bottom: 15px;
          display: block;
          color: #000;
          border-bottom: 1px solid #ededed;
        }
      }
      .drawer-footer {
        text-align: center;
        right: 0;
        left: 0;
        position: fixed;
        width: 270px;
        padding: 10px;
        /* height: 64px; */
        background-color: var(--cognac);
        bottom: 0;
      }
    }
  }
`
export const StyledPopOver = styled(Popover)``

export const StyledPopoverButton = styled(Button)`
  display: flex;
  justify-content: start !important;
  align-items: start;
  text-align: left;
`

export const StyledEmptyCartDrawer = styled(Drawer)`
  .ant-drawer-header {
    padding: 0;
    border: none !important;
    .ant-drawer-close {
      z-index: 1;
      .anticon-close {
        color: var(--cream);
        font-size: 34px;
      }
    }
  }
  .ant-drawer-body {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .empty {
      .ant-col {
        text-align: center;
        h2 {
          color: var(--cognac);
          margin-bottom: 19px;
        }
        p {
          font-size: 18px;
        }
        .ant-btn {
          margin: 35px auto auto auto;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
    .empty {
      .ant-col {
        .ant-btn {
          width: 100%;
        }
      }
    }
  }
`

export const StyledEmptyCartHeader = styled(Row)`
  top: 0;
  height: 90px;
  background-image: url('/images/bg/nav-bg.png');
  background-size: cover;
  z-index: 0;

  h2 {
    font-family: KreonRegular, sans-serif;
    color: var(--cream);
  }
`
