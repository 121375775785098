import styled, { keyframes } from 'styled-components'
import { Row } from 'antd'

const animation = keyframes`
 from {
    background-position: 0 0;
  }
  to {
    background-position: 100% 0;
  }
`

/** Styled Banners */
export const StyledTopBanner = styled.div`
  position: relative;
  overflow: hidden;
  margin-top: -25px;
  margin-bottom: -25px;
  height: 850px;
  background-image: ${(props) => `url(${props.bg})`};
  background-size: cover;
  background-repeat: no-repeat;
  transition: linear 1.0s ease-in-out;
  background-position-y: -155px !important;
  
  /* animation: ${animation} 3s linear infinite alternate;
  animation-duration: 3s; */
  /** Media Queries */
  @media (min-width: 769px) and (max-width: 1024px) {
    height: 600px;
  }
  @media (max-width: 768px) {
    height: 300px;
    background-position-y: -45px !important;
  }

`

export const StyledTopBannerContent = styled.div`
  /* z-index: 3; */
  width: 100%;
  position: absolute;
  bottom: 30px;
  .promo-link {
    padding-top: 100px;
  }
  .cognac-btn {
    padding-left: 65px;
    padding-right: 65px;
  }
  .chili-image {
    padding-bottom: 100px;
    img {
      margin-right: -100px;
    }
  }

  /** Media Queries */
  @media (min-width: 992px) and (max-width: 1024px) {
    .promo-link {
      padding-top: 130px;
    }
  }
  @media (min-width: 770px) and (max-width: 991px) {
    .promo-link {
      padding-top: 20px;
      padding-bottom: 50px;
    }
  }
  @media (min-width: 541px) and (max-width: 768px) {
    .promo-link {
      padding-top: 0;
      padding-bottom: 30px;
    }
  }
  @media (max-width: 420px) {
    .promo-link {
      padding-top: 0;
      padding-bottom: 10px;
    }

    .ant-btn {
      font-size: 16px;
      padding: 0 42px;
    }
  }
`

export const StyledSauceImage = styled.img`
  width: 100%;
  position: absolute;
  max-height: 300px;
  bottom: 0;
  @media (max-width: 540px) {
    width: 100%;
    height: 150px;
  }
`

export const StyledMrPeri = styled.div`
  position: absolute;
  margin-top: -475px;
  margin-left: -150px;

  /** Media Queries */
  @media (min-width: 769px) and (max-width: 991px) {
    margin-top: -200px;
    margin-left: 0;
    img {
      height: 300px;
    }
  }
  @media (min-width: 380px) and (max-width: 768px) {
    margin-top: -200px;
    margin-left: 0;
    img {
      height: 200px;
    }
  }
  @media (max-width: 380px) {
    margin-top: -200px;
    margin-left: -25px;
    img {
      height: 200px;
    }
  }

  @media (min-width: 380px) and (max-width: 420px) {
    margin-left: -18px;
  }

  @media (min-width: 320px) and (max-width: 365px) {
    display: none;
  }
`
/** Styled Banners */

export const Styled = styled(Row)`
  margin-top: -50px;
  overflow: hidden;
  height: 850px;
  .banner-1 {
    margin-top: -85px;
    width: 100%;
    height: auto;
  }
`

export const Styled2 = styled(Row)`
  margin-top: -400px;
  margin-bottom: -185px;
  height: 605px;
  div {
    margin-top: -70px;
  }
  .banner-1 {
    position: absolute;
    width: 100%;
    height: 200px;
  }
`
export const Styled3 = styled(Row)`
  background-image: url('/images/bg/Maroon-BG.png');
  background-size: cover;
  min-height: 440px;
  max-height: auto;
  padding-bottom: 20px;
  h1 {
    width: 340px;
    color: #ffefd1;
    font-size: 52px;
    letter-spacing: 0;
    line-height: 49px;
  }
  .banner-1 {
    margin-top: -45px;
    position: absolute;
  }
  @media only screen and (max-width: 768px) {
    overflow: hidden;
  }
`
export const Styled4 = styled(Row)`
  background-image: url('/images/SaucebarBG.png');
  padding-top: 40px;
  margin-bottom: 100px;
  .mascot {
    position: absolute;
    /* margin-left: -120px; */
  }
  button {
    margin: auto;
    margin-top: -150px;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 50px;
    .mascot {
      display: none;
    }
    button {
      margin-top: -80px;
    }
  }
`
export const Styled5 = styled.div`
  margin-bottom: -15px;
  background-color: #943116;
  height: auto;
  img {
    margin: 0 auto;
    width: 100%;
  }
  @media (max-width: 380px) {
    margin-bottom: 0px;
  }
`
